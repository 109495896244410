<script setup lang="ts">
import VTooltip from "@magnit/core/src/components/VTooltip/VTooltip.vue";
import VIcon from "@magnit/icons/src/components/VIcon/VIcon.vue";
import InfoIcon from "~/assets/svg/info.svg";

interface IDisclaimerProps {
  content?: string | Element;
  placement?: "auto" | "top-end" | "bottom-end";
}

withDefaults(defineProps<IDisclaimerProps>(), {
  content: "",
  placement: "auto",
});
const append = () => document.body;
</script>

<template>
  <client-only v-if="content && append">
    <div class="disclaimer" @click.prevent>
      <VTooltip
        :placement="placement"
        interactive
        :append-to="append"
        trigger="mouseenter click"
      >
        <VIcon class="disclaimer__icon">
          <InfoIcon />
        </VIcon>
        <template #content>
          <div class="disclaimer__content" v-html="content" />
        </template>
      </VTooltip>
    </div>
  </client-only>
</template>

<style lang="postcss">
.disclaimer {
  position: absolute;
  right: var(--pl-unit-x4);
  bottom: var(--pl-unit-x3);
  cursor: help;

  &__icon {
    width: 16px;
    height: 16px;
    background-color: rgb(256 256 256 / 50%);
    backdrop-filter: blur(1px);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
  }

  &__content {
    a {
      color: var(--pl-text-link-default);

      &:visited {
        color: var(--pl-text-link-visited);
      }
    }
  }
}
</style>
